<template>
  <div class="recruitment">
    <div class="recruitment-wrapper">
      <div class="zlxc-container" style="overflow: visible">
        <!-- 面包屑 -->
        <div class="bread-crumbs">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>学驾服务</el-breadcrumb-item>
            <el-breadcrumb-item>驾校风采</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <!-- 顶部跳转banner -->
        <!-- <div class="guide">
          <div class="guide-drive" @click="tapJumpLink('drive')">
            <img
              class="guide-img"
              src="../../assets/zlxcimgs/second_icon_xuechexuzhi@2x.png"
            >
          </div>
          <div class="guide-sense" @click="tapJumpLink('pilot')">
            <img
              class="guide-img"
              src="../../assets/zlxcimgs/second_icon_jiashichangshi@2x.png"
            >
          </div>
        </div> -->
        <!-- 驾校区域 -->
        <div class="driving-demeanor">
          <!-- 驾校列表 -->
          <div class="demeanor">
            <div class="demeanor-search">
              <ul class="search-area" v-show="orignList.length > 0">
                <li :class="['area-node', {active : orignCode == item.areaCode}]" v-for="item in orignList" :key="item.regionCode" @click="selectOrignCode(item.areaCode)">{{item.areaName}}</li>
              </ul>
              <ul class="search-license">
                <li :class="['license-node', {active : licenseCode == item.licenseCode}]" v-for="item in licenseList" :key="item.licenseCode" @click="selectLicenseCode(item.licenseCode)">{{item.licenseName}}</li>
              </ul>
            </div>
            <ul class="demeanor-school" v-if="dataSheetList.length > 0">
              <li class="school-node" v-for="item in dataSheetList" :key="item.trainingSchoolId" @click="tapDriveDetail(item.id)" >
                <div class="node-img" v-if="item.iconUrl">
                  <img
                    class="driving-img"
                    :src="item.iconUrl"
                  >
                </div>
                <div class="node-info">
                  <div class="info-title">{{item.name}}</div>
                  <div class="info-score">
                    <!-- <el-rate class="score-rate" :value="item.starLevel" disabled text-color="#ff9900"></el-rate> -->
                    <p class="score-nub" v-if="item.stars">{{item.stars }}分</p>
                  </div>
                  <div class="info-address">
                    <img
                      class="address-icon"
                      src="../../assets/zlxcimgs/home_icon_location@2x.png"
                    >
                    <p class="address-text">{{item.address}}</p>
                  </div>
                  <div class="info-phone">
                    <img
                      class="phone-icon"
                      src="../../assets/zlxcimgs/home_icon_tell@2x.png"
                    >
                    <p class="phone-text">{{item.phone }}</p>
                  </div>
                </div>
              </li>
            </ul>
            <!-- <div class="demeanor-page" v-if="dataSheetList.length > 0">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="pageObj.total"
                :current-page="pageObj.pageIndex"
                @current-change="handleCurrentChange">
              </el-pagination>
            </div> -->
          </div>
          <!-- 帮找驾校 -->
          <!-- <div class="driving-seek">
            <div class="seek-header">
              <div class="header-write">
                <div class="write-title">帮您找驾校</div>
                <div class="write-sub">关爱生命，关注安全</div>
              </div>
              <div class="header-img">
                <img
                  class="guide-img"
                  src="../../assets/zlxcimgs/second_image_zhaojiaxiao@2x.png"
                >
              </div>
            </div>
            <div class="form">
              <el-form :model="ruleForm" :rules="rules" ref="recruitruleForm" class="rule-form">
                <el-form-item prop="name">
                  <el-input v-model="ruleForm.name" placeholder="请输入您的姓名"></el-input>
                </el-form-item>
                <el-form-item prop="phone">
                  <el-input v-model="ruleForm.phone" placeholder="请输入您的联系方式"></el-input>
                </el-form-item>
                <el-form-item prop="type">
                  <el-select v-model="ruleForm.type" placeholder="请选择驾照类型">
                    <el-option label="C1" value="C1"></el-option>
                    <el-option label="C2" value="C2"></el-option>
                    <el-option label="C3" value="C3"></el-option>
                    <el-option label="B1" value="B1"></el-option>
                    <el-option label="B2" value="B2"></el-option>
                    <el-option label="A1" value="A1"></el-option>
                    <el-option label="A2" value="A2"></el-option>
                    <el-option label="A3" value="A3"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item prop="area">
                  <el-input v-model="ruleForm.area" placeholder="请输入您所在的区域"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="submitForm('recruitruleForm')">提交</el-button>
                  <el-button @click="resetForm('recruitruleForm')">重置</el-button>
                </el-form-item>
              </el-form>
              <div class="form-footer">合适的驾校将会跟您电话联系，请注意接听哦！</div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryIndexOrignList, queryTrainingSchoolList } from '@/api/herbalMedicine'
import { formatTime } from '@/utils/common.js'

export default {
  name: 'Recruitment',
  data () {
    return {
      // 全部区域列表
      orignList: [],
      // 当前选择区域Code
      orignCode: 'all',
      // 全部驾照列表
      licenseList: [
        {licenseCode: "all", licenseName: "不限"},
        {licenseCode: "C1", licenseName: "C1"},
        {licenseCode: "C2", licenseName: "C2"},
        {licenseCode: "C3", licenseName: "C3"},
        {licenseCode: "B1", licenseName: "B1"},
        {licenseCode: "B2", licenseName: "B2"},
        {licenseCode: "A1", licenseName: "A1"},
        {licenseCode: "A2", licenseName: "A2"},
        {licenseCode: "A3", licenseName: "A3"}
      ],
      // 当前选择驾照Code
      licenseCode: 'all',
      ruleForm: {
        // 姓名
        name: '',
        // 联系方式
        phone: '',
        // 驾照类型
        type: 'C1',
        // 区域
        area: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入您的姓名', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入您的联系方式', trigger: 'blur' },
          { min: 7, max: 11, message: '长度在 7 到 11 个数字', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择驾照类型', trigger: 'change' }
        ],
        area: [
          { required: true, message: '请输入您所在的区域', trigger: 'blur' }
        ]
      },
      // 分页信息
      pageObj: {
        pageIndex: 1,
        pageSize: 10,
        total: 0
      },
      // 驾校列表
      dataSheetList: [],
    }
  },
  created() {
    // 全部区域列表
    this.getQueryIndexOrignList()
    // 查询驾校列表
    this.getQueryTrainingSchoolList()
  },
  mounted() {},
  computed: {},
  methods: {
    // 全部区域列表
    getQueryIndexOrignList () {
      queryIndexOrignList().then(res => {
        res.data.unshift({areaCode: "all", areaName: "不限"})
        this.orignList = res.data
      })
    },
    // 选择当前区域Code
    selectOrignCode (code) {
      if (this.orignCode == code) return
      this.orignCode = code
      this.pageObj.pageIndex = 1
      this.getQueryTrainingSchoolList()
    },
    // 选择当前驾照Code
    selectLicenseCode (code) {
      if (this.licenseCode == code) return
      this.licenseCode = code
      this.pageObj.pageIndex = 1
      this.getQueryTrainingSchoolList()
    },
    // 查询驾校列表
    getQueryTrainingSchoolList () {
      queryTrainingSchoolList({
        // isPublish: 1,
        pageIndex: this.pageObj.pageIndex,
        pagesize: this.pageObj.pageSize,
        district: this.orignCode == 'all' ? '' : this.orignCode,
        businessScope: this.licenseCode == 'all' ? '' : this.licenseCode
      }).then(res => {
        if (res.data.length > 0) {
          this.pageObj.total = res.count
          this.dataSheetList = res.data
        } else {
          this.pageObj.total = res.count
          this.dataSheetList = res.data
          this.showMessage('未找到结果', "error");
        }
        for (var item of this.dataSheetList) {
           item.starLevel = parseFloat(item.starLevel);
        }
      })
    },
    // 当前currentPage 改变时会触发
    handleCurrentChange (curPage) {
      this.pageObj.pageIndex = curPage
      this.getQueryTrainingSchoolList()
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    tapDriveDetail (id) {
      this.$router.push({
        name:'Drivedetail',
        query: {
          id
        }
      })
    },
    tapJumpLink (atom) {
      this.$router.push({
        name:'DriveKnowledge',
        query: {
          atomtype: atom
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
